<template lang="pug">
  div
    base-snackbar(
      :errors.sync="fdPrgmCategoryGetErrors"
    )
    base-snackbar(
      :errors.sync="stockGetErrors"
    )
    base-snackbar(
      :errors.sync="fdPrgmPostErrors"
    )
    v-row
      v-col(cols="12")
        v-autocomplete(
          v-if="!editMode"
          label="Stock"
          :items="stockList"
          v-model="stock"
          :loading="stockGetting"
          :disabled="!stockList.length"
          item-text="description"
          :error-messages="fdPrgmPostErrors.stock_id"
          return-object
        )
          template(v-slot:selection="{ item }")
            span.stocks-description.blue-grey--text {{ item.description }}
        v-text-field(
          v-else
          label="Stock"
          :value="stockId"
          disabled
        )
      v-col(cols="12")
        v-autocomplete(
          label="Feeding Program Category"
          v-model="feedingProgramCategoryId"
          :disabled="!fdPrgmCategoryList.length"
          item-value="id"
          item-text="description"
          :loading="fdPrgmCategoryGetting"
          :items="fdPrgmCategoryList"
          :error-messages="fdPrgmPostErrors.feeding_program_category_id"
        )
      v-col(cols="6").pt-0
        v-text-field(
          label="Day Start"
          v-model="dayStart"
          :error-messages="fdPrgmPostErrors.day_start"
          type="number"
        )
      v-col(cols="6").pt-0
        v-text-field(
          label="Day End"
          v-model="dayEnd"
          :error-messages="fdPrgmPostErrors.day_end"
          type="number"
        )
      v-col(cols="12")
        v-text-field(
          label="Body Weight"
          v-model="bodyWeight"
          type="number"
          :error-messages="fdPrgmPostErrors.body_weight"
        )
      v-col(cols="12")
        v-text-field(
          label="Expected Weight"
          v-model="expectedWeight"
          :error-messages="fdPrgmPostErrors.expected_weight"
          type="number"
        )
    v-container
      v-btn(
        color="primary"
        dense
        small
        dark
        :loading="fdPrgmPosting"
        @click="sendServerRequest"
      )
        span Save
      v-btn.ml-2(
        v-if="editMode"
        color="error"
        dense
        small
        dark
        @click="closeEditMode"
      )
        span Cancel
</template>
<style lang="scss" scope>
  span.stocks-description {
    font-size: 0.7em;
  }
</style>
<script>
import feedingProgramRepository from '@/repositories/feeding-program.repository'
import feedingProgramCategoryRepository from '@/repositories/feeding-program-category.repository'
import stockRepository from '@/repositories/stock.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [feedingProgramCategoryGetVars, feedingProgramCategoryGetVarNames] = requestVars({ identifier: 'fd-prgm-category' })
const [stockGetVars, stockGetVarNames] = requestVars({ identifier: 'stock' })
const [fdPrgmStoreVars, fdPrgmStoreVarNames] = requestVars({ identifier: 'fd-prgm', request: 'post' })

const inputVars = () => ({
  id: null,
  stockId: null,
  stock: {},
  feedingProgramCategoryId: null,
  stockCategoryId: null,
  bodyWeight: null,
  dayStart: null,
  dayEnd: null,
  expectedWeight: null,
})

export default {
  name: 'FeedingProgramCreate',
  props: {
    defaultFieldValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ...stockGetVars,
    ...feedingProgramCategoryGetVars,
    ...fdPrgmStoreVars,
    ...inputVars(),
  }),
  created () {
    this.getStocks()
    this.listenToWebsocketEvents()
  },
  computed: {
    selectFdPrgmCategory () {
      return this.$objectChangeKeyCase(this.fdPrgmCategoryList.find(item => item.id === this.feedingProgramCategoryId))
    },
    editMode () {
      return !this.$objectEmpty(this.defaultFieldValues)
    },
  },
  watch: {
    stock (item) {
      const { id, stockCategoryId } = this.$objectChangeKeyCase(item)
      this.stockId = id
      this.stockCategoryId = stockCategoryId
    },
    defaultFieldValues (item) {
      this.populateInputs(item)
    },
    // selectFdPrgmCategory ({ bodyWeight, dayStart, dayEnd, expectedWeight }) {
    //   this.bodyWeight = bodyWeight
    //   this.dayStart = dayStart
    //   this.dayEnd = dayEnd
    //   this.expectedWeight = expectedWeight
    // },
    stockId (item) {
      if (item) this.getFeedingPorgramCategories()
    },
  },
  methods: {
    populateInputs (item) {
      Object.keys(item).forEach(key => {
        if (this[key.snakeToCamelCase()] !== item[key]) this[key.snakeToCamelCase()] = item[key]
      })
    },
    getStocks () {
      if (this.stockGetting) return
      const requesthandler = new VueRequestHandler(this, stockGetVarNames)
      const repository = stockRepository.index
      const params = this.getStockIndexParams()
      requesthandler.execute(repository, [params])
    },
    getStockIndexParams () {
      return {
        noHas: ['feedingProgram', 'defaultDetail'].join(','),
      }
    },
    getFeedingPorgramCategories () {
      if (this.fdPrgmCategoryGetting) return
      const requestHandler = new VueRequestHandler(this, feedingProgramCategoryGetVarNames)
      const repository = feedingProgramCategoryRepository.stockCategory
      requestHandler.execute(repository, [this.stockCategoryId])
    },
    sendServerRequest () {
      if (this.editMode) {
        this.updateFeedingProgram()
        return
      }
      this.storeFeedingProgram()
    },
    storeFeedingProgram () {
      if (this.fdPrgmPosting) return
      const requestHandler = new VueRequestHandler(this, fdPrgmStoreVarNames)
      const repository = feedingProgramRepository.store
      const { data } = this.getInputFieldData()
      requestHandler.execute(repository, [data], this.storeFeedingProgramStatusOk)
    },
    updateFeedingProgram () {
      if (this.fdPrgmPosting) return
      const requestHandler = new VueRequestHandler(this, fdPrgmStoreVarNames)
      const repository = feedingProgramRepository.update
      const { id, data } = this.getInputFieldData()
      requestHandler.execute(repository, [id, data], this.updateFeedingProgramStatusOk)
    },
    updateFeedingProgramStatusOk () {
      this.closeEditMode()
    },
    storeFeedingProgramStatusOk () {
      this.resetInputs()
    },
    resetInputs () {
      const defaults = inputVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
      this.fdPrgmCategoryList = []
    },
    getInputFieldData () {
      const excluded = ['stock']
      const { id } = this
      const data = Object.keys(inputVars())
        .filter(key => !excluded.includes(key))
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
      return { id, data }
    },
    listenToWebsocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'FeedingProgram') {
        this.feedingProgramDBStoreEvent(data)
        return
      }
      if (model === 'Stock') {
        this.stockDBStoreEvent(data)
      }
    },
    feedingProgramDBStoreEvent (data) {
      this.removeFromStockList(data)
    },
    stockDBStoreEvent (data) {
      this.stockList = this.stockList.objectInsertion(data)
        .map(item => Object.assign({ ...item }))
    },
    removeFromStockList (data) {
      this.stockList = this.stockList.filter(item => item.id !== data.stock_id)
    },
    closeEditMode () {
      this.resetInputs()
      this.clearDefaultFieldValues()
    },
    clearDefaultFieldValues () {
      this.$emit('update:defaultFieldValues', {})
    },
  },
}
</script>